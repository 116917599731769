import React, {useEffect, useState} from 'react';
import {useFormInstance} from '../../Form/useFormInstance';
import {ExtendedFieldsForm} from '../../Form/ExtendedFieldsForm/ExtendedFieldsForm';
import {ExtendedFieldsFragment} from '../../../../gql/graphql';
import {getExtendedFieldsFormInitialState} from '../../Form/ExtendedFieldsForm/ExtendedFieldsForm.utils';
import {ButtonPriority, StatesButton, StatesButtonStates, ThreeDotsLoader} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from './StandaloneExtendedFieldsForm.st.css';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../constants';
import {CustomerDetailsForm} from '../../Form/CustomerDetailsForm/CustomerDetailsForm';
import {CustomerDetailsFormSection} from '../../../../types/app.types';
import {
  getCustomerDetailsFormInitialState,
  getExtendedFieldValuesFromCustomerDetailsForm,
} from '../../Form/CustomerDetailsForm/CustomerDetailsForm.utils';
import {FormValues} from '@wix/form-fields';

export enum StandaloneExtendedFieldsFormDataHook {
  root = 'StandaloneExtendedFieldsForm.extendedFieldsForm',
  customerDetailsForm = 'StandaloneExtendedFieldsForm.customerDetailsForm',
  apply = 'StandaloneExtendedFieldsForm.apply',
}

const StandaloneExtendedFieldsFormInternal = ({extendedFields}: {extendedFields?: ExtendedFieldsFragment}) => {
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();
  const {
    checkoutStore: {setExtendedFields, checkout},
    formsStore: {dataExtendedFieldsTargets},
    checkoutSettingsStore: {checkoutComposerEnabled, checkoutSettings},
  } = useControllerProps();
  const formInstance = useFormInstance(() => getExtendedFieldsFormInitialState(extendedFields));
  const customerDetailsFormInstance = useFormInstance(
    () =>
      getCustomerDetailsFormInitialState({
        checkoutComposerEnabled,
        checkoutSettings,
        extendedFields: checkout.extendedFields,
      }) as FormValues
  );

  const [buttonState, setButtonState] = useState<StatesButtonStates>(StatesButtonStates.IDLE);
  const shouldShowConsolidatedFormWithExtendedFields = experiments.enabled(
    SPECS.UseSingleCustomerDetailsFormWithComposer
  );

  const onApplyClick = async () => {
    if (shouldShowConsolidatedFormWithExtendedFields) {
      if (await customerDetailsFormInstance.isValid()) {
        const extendedFields = getExtendedFieldValuesFromCustomerDetailsForm(
          customerDetailsFormInstance.data.formValues,
          dataExtendedFieldsTargets
        );

        setButtonState(StatesButtonStates.IN_PROGRESS);
        await setExtendedFields(extendedFields);
      }
    } else if (await formInstance.isValid()) {
      setButtonState(StatesButtonStates.IN_PROGRESS);
      await setExtendedFields(formInstance.data.formValues);
    }
  };

  useEffect(
    () => {
      if (buttonState === StatesButtonStates.IN_PROGRESS) {
        setButtonState(StatesButtonStates.SUCCESS);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [extendedFields]
  );

  return (
    <div className={classes.root} data-hook={StandaloneExtendedFieldsFormDataHook.root}>
      {shouldShowConsolidatedFormWithExtendedFields ? (
        <CustomerDetailsForm
          {...customerDetailsFormInstance.data}
          formSections={[CustomerDetailsFormSection.extendedFields]}
          dataHook={StandaloneExtendedFieldsFormDataHook.customerDetailsForm}
        />
      ) : (
        <ExtendedFieldsForm {...formInstance.data} />
      )}
      <StatesButton
        data-hook={StandaloneExtendedFieldsFormDataHook.apply}
        upgrade
        state={buttonState}
        idleContent={localeKeys.checkout.additionalInfo.custom_field.apply()}
        inProgressContent={<ThreeDotsLoader />}
        onNotificationEnd={() => setButtonState(StatesButtonStates.IDLE)}
        fullWidth
        priority={ButtonPriority.basicSecondary}
        className={classes.applyButton}
        onClick={() => {
          void onApplyClick();
        }}
      />
    </div>
  );
};

export const StandaloneExtendedFieldsForm = React.memo(StandaloneExtendedFieldsFormInternal);
